/* == body */
body {
  font-family: "Montserrat", sans-serif;
  color: #333;
  background-color: rgba(100, 100, 100, 0.16);
}

/* == headers */
h1,
h2,
h3,
h4,
h5,
h6,
nav {
  font-family: "Raleway", sans-serif;
}

.border-bottom {
  /* border-color: red !important; */
  /* border: solid 1px red !important; */
}

/* == navs */
nav a,
nav a:hover,
#responsive-navbar-nav .dropdown-toggle.nav-link {
  color: #eee;
}

.dropdown-menu.show a {
  color: #eee;
}

.dropdown-menu.show,
.dropdown-menu.show a:hover {
  background-color: #353a40;
  right: 0;
  color: #eee;
  left: auto;
}

.dropdown-menu.show a.active.dropdown-item.active,
.dropdown-menu.show a:hover {
  background-color: #222;
}

.centered-menu,
.centered-menu + a {
  text-align: center;
}

/* == carousel */
.carousel-container {
  text-align: center;
}
a.carousel-control-prev > span.carousel-control-prev-icon,
a.carousel-control-next > span.carousel-control-next-icon {
  height: 2em;
  width: 2em;
}

/* == images */
figure {
  text-align: center;
}

figcaption {
  color: #777;
}
